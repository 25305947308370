import * as React from "react";
import { CallToActionProps } from "./typings";
import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSText, EGDSHeadingProps, EGDSSubheading, EGDSHeading } from "@egds/react-core/text";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSScrim, EGDSScrimType, EGDSScrimTypes } from "@egds/react-core/scrim";
import { inject, observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";
import { locKeys } from "./l10n/locKeys";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { CTACarouselRegionContext } from "../CTACarouselRegion/CTACarouselRegion";
import { CallToActionFlexModuleResult } from "typings/microserviceModels/call-to-action-flex-module";
import { SimpleContainerContext } from "../SimpleContainer/SimpleContainer";
import { ToggleSingleContainerContext } from "../ToggleContainer/views/ToggleSingleContainer";
import { ResponsiveToggleContainerContext } from "../ResponsiveToggleContainer/ResponsiveToggleContainer";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { EGDSPrimaryButton } from "@egds/react-core/button";
import { ViewExtraLarge, ViewLarge, Viewport } from "@shared-ui/viewport-context";
import { useLocation } from "react-router";
import { ModuleViewType } from "../Editorial/factory/EditorialContentFactory";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const ENTER_BUTTON_KEY_VALUE = "Enter";
const SPACE_BUTTON_KEY_VALUE = " ";

const CardMediaImage = (props: CallToActionFlexModuleResult) => {
  const { callToAction, imageAspectRatio, widthSmall } = props;
  const media = callToAction?.media?.[0];
  if (!media) {
    return null;
  }

  /**
   * Optimize 16-9 image size on carousel with more than 2 CallToActions.
   * Carousel wtih more than 2 CallToActions does not require high image quality
   */
  const numberOfCarouselItems = React.useContext(CTACarouselRegionContext).carouselSize;

  // Map the appropriate crop dimension to the carousel size, defaults to size used by 6 items
  const itemsToCropDimension = (numberOfCarouselItems: number) => {
    switch (numberOfCarouselItems) {
      case 3: {
        return { width: 384, height: 216 };
      }
      case 4: {
        return { width: 285, height: 161 };
      }
      case 5: {
        return { width: 226, height: 127 };
      }
      default: {
        return { width: 186, height: 105 };
      }
    }
  };

  if (numberOfCarouselItems && numberOfCarouselItems > 2 && imageAspectRatio === "16-9") {
    return (
      <BlossomImage
        alt={media.mediaAlt}
        src={media.mediaUrl}
        imageRatio={imageAspectRatio}
        cropDimension={itemsToCropDimension(numberOfCarouselItems)}
        lazyLoading="lazy"
      />
    );
  }

  return (
    <BlossomImage
      alt={media.mediaAlt}
      src={media.mediaUrl}
      imageRatio={imageAspectRatio}
      placeholderImage
      lazyLoading="lazy"
      widthSmall={widthSmall}
    />
  );
};

const uitkFigureAspectRatio = (ratio: string) => {
  switch (ratio) {
    case "1-1": {
      return EGDSFigureAspectRatioType.R1_1;
    }
    case "3-2": {
      return EGDSFigureAspectRatioType.R3_2;
    }
    case "3-4": {
      return EGDSFigureAspectRatioType.R3_4;
    }
    case "4-3": {
      return EGDSFigureAspectRatioType.R4_3;
    }
    case "21-9": {
      return EGDSFigureAspectRatioType.R21_9;
    }
    default: {
      return EGDSFigureAspectRatioType.R16_9;
    }
  }
};

const matchAffinityToLocKey = (callToActionName: string): string => {
  return locKeys.has(callToActionName) ? locKeys.get(callToActionName) || "" : "";
};

const returnCitiCode = (View: string): string => {
  let citiCode = "";
  if (View === ModuleViewType.Grid3) {
    const { search: searchUrl } = useLocation();
    const INITIAL_INDEX = searchUrl.indexOf("=") + 1;
    const FINAL_INDEX = searchUrl.indexOf("=") + 4;
    return searchUrl.includes("citiCode") ? searchUrl.substring(INITIAL_INDEX, FINAL_INDEX) : "";
  }

  return citiCode;
};

const getViewSettings = (
  model: CallToActionFlexModuleResult,
  moduleName: string,
  formatText: (key: string, ...args: any[]) => string
) => {
  const { excludeRfrr, callToAction, newWindowOnButtonClick } = model;
  const url = callToAction?.callToActionButton?.url ?? "";
  const editorialSpacing: EGDSSpacingProps = { padding: { blockstart: "three", medium: { blockend: "three" } } };
  const snippetSpacing: EGDSSpacingProps = { padding: { blockstart: "three", large: { blockend: "three" } } };
  const callToActionName = model.title && model.title.replace(" ", "_");
  const rfrr = `${moduleName}.${callToActionName}.${Action.CLICK}`;
  let showEditorialContent = false;
  let cardClassName = "";
  let headingSize: EGDSHeadingProps["size"] = 5;
  let figureClassName = "";
  let cardScrimType: EGDSScrimTypes = EGDSScrimType.OVERLAY;
  let callToActionLabel = "";
  let callToActionSubLabel = "";
  let callToActionTitle = model.title;
  let callToActionLink = url;
  let uitkSpacing: EGDSSpacingProps = { padding: { blockstart: "three" } };
  let preventDefaultOnClick = false;
  let tabIndex;
  const citiCode = returnCitiCode(model.view);

  try {
    if (!excludeRfrr && model.view != ModuleViewType.Grid3) {
      const convertedUrl = new URL(url);
      convertedUrl.searchParams.set("rfrr", rfrr);
      callToActionLink = convertedUrl.toString();
    }
  } catch {
    callToActionLink = `${url}?rfrr=${rfrr}`;
  }

  switch (model.view) {
    case "affinity": {
      headingSize = 6;
      cardClassName = "cardAffinityHeading";
      figureClassName = "uitk-card-roundcorner-all";
      callToActionLabel = model.callToAction?.callToActionButton?.label;
      break;
    }
    case "theme-selector": {
      headingSize = 4;
      cardClassName = "cardAffinityHeading";
      figureClassName = "uitk-card-roundcorner-all";
      if (model.callToAction.callToActionButton.label === "l10n") {
        callToActionLabel = formatText(matchAffinityToLocKey(callToActionName.toLowerCase()));
        callToActionTitle = callToActionLabel;
      } else {
        callToActionLabel = model.callToAction?.callToActionButton?.label;
      }
      preventDefaultOnClick = true;
      tabIndex = 0;
      callToActionLink = url;
      break;
    }
    case "editorial": {
      figureClassName = "uitk-card-roundcorner-top-left uitk-card-roundcorner-top-right";
      cardScrimType = EGDSScrimType.BOTTOM;
      uitkSpacing = editorialSpacing;
      showEditorialContent = !!model.callToAction.text;
      callToActionLabel = model.title;
      callToActionSubLabel = model.subtitle;
      break;
    }
    case "snippet": {
      figureClassName = "uitk-card-roundcorner-top-left uitk-card-roundcorner-bottom-left";
      cardScrimType = EGDSScrimType.BOTTOM;
      uitkSpacing = snippetSpacing;
      showEditorialContent = !!model.callToAction.text;
      callToActionLabel = model.title;
      break;
    }
    case "full-bleed-vertical": {
      figureClassName = "uitk-card-roundcorner-all";
      cardScrimType = EGDSScrimType.BOTTOM;
      uitkSpacing = editorialSpacing;
      showEditorialContent = !!model.callToAction.text;
      callToActionLabel = model.title;
      callToActionSubLabel = model.subtitle;
      break;
    }
    case ModuleViewType.Grid3: {
      uitkSpacing = editorialSpacing;
      showEditorialContent = !!model.callToAction.text;
      callToActionLabel = model.title;
      callToActionSubLabel = model.subtitle;
      callToActionLink = citiCode ? `${callToActionLink}&citiCode=${citiCode}` : callToActionLink;
      break;
    }
    default:
  }

  return {
    headingSize,
    cardClassName,
    figureClassName,
    cardScrimType,
    callToActionLabel,
    callToActionSubLabel,
    callToActionTitle,
    callToActionLink,
    uitkSpacing,
    showEditorialContent,
    preventDefaultOnClick,
    tabIndex,
    newWindowOnButtonClick,
  };
};

export const CallToAction = inject(
  "analytics",
  "compositionStore",
  "context",
  "flexModuleModelStore"
)(
  observer((props: CallToActionProps) => {
    const { templateComponent, flexModuleModelStore, compositionStore, analytics } = props;

    if (!templateComponent) {
      return null;
    }
    const {
      metadata: { id },
      config,
    } = templateComponent;
    const componentName = templateComponent.type || "";
    const fmId = getFmId(templateComponent);

    const model = flexModuleModelStore.getModel(id) as CallToActionFlexModuleResult | null;

    if (config.ajaxOnly || !model) {
      return null;
    }

    const { formatText } = useLocalization();
    const simpleContainerContext = React.useContext(SimpleContainerContext);
    const toggleContainerContext = React.useContext(ToggleSingleContainerContext);
    const responsiveToggleContainerContext = React.useContext(ResponsiveToggleContainerContext);

    const ctaCarouselContext = React.useContext(CTACarouselRegionContext);

    const shouldRenderBorder =
      simpleContainerContext.modulesHaveBorder ||
      toggleContainerContext.modulesHaveBorder ||
      responsiveToggleContainerContext.modulesHaveBorder ||
      ctaCarouselContext.hasBorder;
    const viewProps = getViewSettings(model, componentName, formatText);
    const isAlignLeft = model.view === "editorial" || model.view === "snippet" || model.view === "full-bleed-vertical";

    const keyboardPress = (e: React.KeyboardEvent) => {
      //button elements should allow click on enter or space when a keydown event occurs. Allow by default when a mouse event.
      const allowClick = e.key === ENTER_BUTTON_KEY_VALUE || e.key === SPACE_BUTTON_KEY_VALUE;

      if (allowClick) {
        cardClicked(e);
      }
    };

    const cardClicked = (e: React.SyntheticEvent) => {
      const rfrr = `nativeMarqueeTile.tile.click`;

      if (compositionStore && model.nextScene) {
        compositionStore.changeScene(model.nextScene);
      }

      const trackingInfo: FlexTrackingInfo = {
        moduleName: componentName,
        rfrr,
        action: Action.CLICK,
      };

      sendDelayedTrackEvent(trackingInfo, analytics);

      if (viewProps.preventDefaultOnClick) {
        e.preventDefault();
      }
    };

    return (
      <EGDSSpacing {...viewProps.uitkSpacing}>
        <div
          id={id}
          data-fm={fmId}
          data-fm-title-id={config.fmTitleId}
          className="CallToAction"
          onClick={cardClicked}
          onKeyDown={keyboardPress}
          tabIndex={viewProps.tabIndex}
        >
          <EGDSText align={isAlignLeft ? undefined : "center"}>
            <EGDSCard overflow border={shouldRenderBorder}>
              {model.view === "snippet" ? (
                <EGDSLayoutGrid columns={4}>
                  <>
                    <EGDSLayoutGridItem colSpan={1}>
                      <EGDSFigure
                        ratio={uitkFigureAspectRatio(model.imageAspectRatio)}
                        className={viewProps.figureClassName}
                      >
                        <CardMediaImage {...model} />
                        <EGDSScrim type={viewProps.cardScrimType}>
                          {viewProps.cardScrimType !== EGDSScrimType.BOTTOM && (
                            <EGDSHeading className={viewProps.cardClassName} size={viewProps.headingSize}>
                              {viewProps.callToActionLabel}
                            </EGDSHeading>
                          )}
                          {viewProps.cardScrimType === EGDSScrimType.BOTTOM && (
                            <EGDSSubheading tag="h3" className={viewProps.cardClassName}>
                              {viewProps.callToActionLabel}
                            </EGDSSubheading>
                          )}
                          <EGDSText align="left" size={300}>
                            {viewProps.callToActionSubLabel}
                          </EGDSText>
                        </EGDSScrim>
                      </EGDSFigure>
                    </EGDSLayoutGridItem>
                    <EGDSLayoutGridItem colSpan={3}>
                      {viewProps.showEditorialContent && (
                        <EGDSCardContentSection>
                          <EGDSText size={200} align="left">
                            <div dangerouslySetInnerHTML={{ __html: model.callToAction.text }} />
                          </EGDSText>
                        </EGDSCardContentSection>
                      )}
                    </EGDSLayoutGridItem>
                  </>
                </EGDSLayoutGrid>
              ) : model.view === ModuleViewType.Grid3 ? (
                <>
                  <EGDSSpacing padding={{ large: { blockend: "six" }, extra_large: { blockend: "six" } }}>
                    <Viewport>
                      <ViewExtraLarge>
                        <EGDSPrimaryButton type="button" href={viewProps.callToActionLink}>
                          Apply Now
                        </EGDSPrimaryButton>
                      </ViewExtraLarge>
                      <ViewLarge>
                        <EGDSPrimaryButton type="button" href={viewProps.callToActionLink}>
                          Apply Now
                        </EGDSPrimaryButton>
                      </ViewLarge>
                    </Viewport>
                  </EGDSSpacing>
                </>
              ) : (
                <>
                  <EGDSFigure
                    ratio={uitkFigureAspectRatio(model.imageAspectRatio)}
                    className={viewProps.figureClassName}
                  >
                    <CardMediaImage {...model} />
                    <EGDSScrim type={viewProps.cardScrimType}>
                      {viewProps.cardScrimType !== EGDSScrimType.BOTTOM && (
                        <EGDSHeading className={viewProps.cardClassName} size={viewProps.headingSize}>
                          {viewProps.callToActionLabel}
                        </EGDSHeading>
                      )}
                      {viewProps.cardScrimType === EGDSScrimType.BOTTOM && (
                        <EGDSSubheading tag="h3" className={viewProps.cardClassName}>
                          {viewProps.callToActionLabel}
                        </EGDSSubheading>
                      )}
                      <EGDSText align="left" size={300}>
                        {viewProps.callToActionSubLabel}
                      </EGDSText>
                    </EGDSScrim>
                  </EGDSFigure>
                  {viewProps.showEditorialContent && (
                    <EGDSCardContentSection>
                      <EGDSText size={200} align="left">
                        <div dangerouslySetInnerHTML={{ __html: model.callToAction.text }} />
                      </EGDSText>
                    </EGDSCardContentSection>
                  )}
                </>
              )}
              {!model.nextScene && (
                <EGDSCardLink>
                  <a
                    data-testid="call-to-action-link"
                    href={viewProps.callToActionLink}
                    target={viewProps.newWindowOnButtonClick ? "_blank" : "_self"}
                    rel={viewProps.newWindowOnButtonClick ? "noopener noreferrer" : undefined}
                  >
                    {viewProps.callToActionTitle}
                    {viewProps.newWindowOnButtonClick && (
                      <EGDSIcon
                        name="open_in_new"
                        size={EGDSIconSize.SMALL}
                        title={formatText("opens.in.new.window")}
                      />
                    )}
                  </a>
                </EGDSCardLink>
              )}
            </EGDSCard>
          </EGDSText>
        </div>
      </EGDSSpacing>
    );
  })
);

export default CallToAction;
